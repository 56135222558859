import React from 'react';
import { Link } from 'gatsby';

import Layout from '@components/layout';
import SEO from '@components/seo';
import { PortableTextBlock } from '@modules/portableTextBlock';
import { useSiteConfig } from '@querys/useSiteConfig';

import Exit from '@svg/exit.svg';

const Privacy = () => {
  const { privacy } = useSiteConfig();

  return (
    <Layout>
      <SEO title='Privacy Policy' />
      <Link to='/promo'>
        <Exit className='ml-auto mt-6 mr-6 md:mt-12 md:mr-12 w-6 md:w-12' />
      </Link>
      <div className='terms grid grid-cols-14 bg-liaise-orange py-12 md:py-32'>
        <div className='col-start-2 col-end-14 lg:col-start-4 lg:col-end-12 prose md:prose-lg max-w-none'>
          {privacy.heading && (
            <h2 className='text-center md:pb-8'>{privacy.heading}</h2>
          )}
          <PortableTextBlock text={privacy.copy} />
        </div>

        <div className='col-start-2 col-end-14 lg:col-start-4 lg:col-end-12 mt-12'>
          <Link className='underline mt-5 font-bold' to='/promo'>
            Back to Liaise website
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Privacy;
